import React, { Component } from "react";
import Layout from "../components/layout";
import Intro from "../components/intro";
import MapContact from "../images/map_contact.png";
import Nav from "../components/nav";
import BlogItem from "../components/blogItem";
import { Link } from "gatsby"

class ContactPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            pageContent: [],
            ispageLoader: false,
            isBlogLoaded: false,
            blogContent: [],
        };
    }

    async componentDidMount() {
        this.getPageDetailsById();
        this.getAllBlog();
    }

    componentWillReceiveProps(nextProps) {
        this.getPageDetailsById();
        this.getAllBlog();
    }
    getPageDetailsById() {
        fetch("https://tatva.com/backendapi/api/v1/cms/614c40ee64b63040be8aec8a")
            .then((res) => res.json())
            .then(
                (result) => {
                    this.setState({
                        ispageLoader: true,
                        pageContent: result,
                    });
                },
                (error) => {
                    this.setState({
                        ispageLoader: true,
                        error,
                    });
                }
            );
    }

    getAllBlog() {
        fetch("https://tatva.com/backendapi/api/v1/cms/by-type/resources")
            .then(res => res.json())
            .then(
                (result) => {
                    const filteredDates = result.data
                    console.log("filteredDates", filteredDates)
                    this.setState({
                        isBlogLoaded: true,
                        blogContent: filteredDates
                    });
                },
                (error) => {
                    this.setState({
                        isBlogLoaded: true,
                        error
                    });
                }
            )
    }

    render() {
        const { pageContent, ispageLoader, isBlogLoaded, blogContent } = this.state;
        return (
            <Layout>
                {ispageLoader ? (
                    <Nav
                        active="home"
                        pageTitle={pageContent.data?.title}
                        title={pageContent.data?.document_title}
                        description={pageContent.data?.meta_description}
                        keywords={pageContent.data?.meta_tag}
                    ></Nav>
                ) : (
                    ""
                )}
                <Intro
                    title={pageContent?.data?.title}
                    description={pageContent?.data?.description}
                >
                    {" "}
                </Intro>

                <div className="main-container">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9 col-sm-12">
                                <div className="blog-container">
                                    {isBlogLoaded ?
                                        blogContent.length !== 0 ?
                                            <React.Fragment>
                                                {blogContent.map(item => (
                                                    <div className="" key={item._id}>
                                                        <BlogItem
                                                            category_show="false"
                                                            category={item.category}
                                                            title={item.title}
                                                            author={item.created_by}
                                                            date={item.publish_date}
                                                            short_content={item.description}
                                                            slug={item.slug}
                                                            description={item.description}
                                                            image={item.image}
                                                            download_show="true"
                                                        >
                                                        </BlogItem>
                                                    </div>
                                                ))}

                                            </React.Fragment>
                                            :
                                            <div className={'blog-item'}>
                                                <div className="inner">
                                                    <div className="notFound">
                                                    No record found
                                                </div>
                                                </div>
                                            </div>
                                        : ''}
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-12">
                                <div className="our-resources-box">
                                    <h4> Our Blog </h4>
                                    <p>Our blog provides legal and commercial information, analysis and updates of national and regional relevance.</p>
                                    <Link title='View Our Resources' to={"/blog/"}>
                                        View Our Blog
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default ContactPage;
